import Carousel from 'react-bootstrap/Carousel';
import './carusel.css'

function CarouselImage({images}) {
    return (
        <div className='caurasel-container'>
            <Carousel >
                {images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="carousel-image"
                            src={image} 
                            alt={`Slide ${index + 1}`}
                        />
                        <Carousel.Caption>
                            <h3>{image.label}</h3> 
                            <p>{image.caption}</p> 
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default CarouselImage;