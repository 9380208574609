import {
    FETCH_ITEMS_MATERIALS_REQUEST,
    FETCH_ITEMS_MATERIALS_SUCCESS,
    FETCH_ITEMS_MATERIALS_FAILURE
} from '../actions/ItemMetirialActions';

const initialState = {
    loading: false,
    itemsMetirials: [],
    error: ''
};

const ItemMetirialReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ITEMS_MATERIALS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FETCH_ITEMS_MATERIALS_SUCCESS:
            return {
                ...state,
                loading: true,
                itemsMetirials: action.payload,
                error: ''
            };

        case FETCH_ITEMS_MATERIALS_FAILURE:
            return {
                ...state,
                loading: false,
                itemsMetirials: {},
                error: action.payload
            };

        default:
            return state;
    }
};

export default ItemMetirialReducer;

