import {
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_REQUEST,
    FETCH_ITEMS_SUCCESS,
    FETCH_ITEM_DETAILS_SUCCESS,
    FETCH_ITEM_DETAILS_REQUEST,
    FETCH_ITEM_DETAILS_FAILURE
} from "../actions/itemActions";

const initialState = {
    loading: false,
    items: [],
    itemDetails:{},
    error: ''
};

const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        // For fetching the list of items
        case FETCH_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FETCH_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
                error: '',
            };

        case FETCH_ITEMS_FAILURE:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.payload,
            };

        // For fetching item details
        case FETCH_ITEM_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FETCH_ITEM_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                itemDetails: action.payload, // Store item details in state
                error: '',
            };

        case FETCH_ITEM_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                itemDetails: {}, // Reset item details on failure
                error: action.payload,
            };

        default:
            return state;
    }
};

export default itemReducer;