import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import store from './redux/store';

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container); 
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

