import React, { useState } from 'react';
import logo from '../assest/logog.png';
import './styles/passreset.css';
import { Link } from 'react-router-dom';

function Passreset() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically send the email to the server
        setMessage('A password reset link has been sent to your email.');
    };

    return (
        <div className='pass-reset'>
            <div className='pass-reset-container'>
                <div className='pass-reset-box'>
                    <img src={logo} width={200} className='mt-4' alt='Logo' />
                    <div className='pass-reset-text'>
                        <h6>
                            Enter the email address associated with your account and we'll
                            send you a link to reset your password.
                        </h6>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mt-5'>
                        <form onSubmit={handleSubmit} className='pass-reset-form'>
                            <div className='form-group text-center'>
                                <input
                                    type='email'
                                    className='input-Box-password-reset'
                                    placeholder='Enter your email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <button type='submit' className='reset-button'>
                                    Send Reset Link
                                </button>
                            </div>
                            <div className='text-center d-flex justify-content-center align-item-center mt-5'>
                                <h6>Don't have an account ? </h6>
                                <Link to='/register'><h6 className='mx-2'> Sign up </h6></Link>
                            </div>
                        </form>
                    </div>
                    {message && <div className='reset-message'>{message}</div>}
                </div>
            </div>

        </div>
    );
}

export default Passreset;
