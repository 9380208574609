import axios from 'axios';

//actions types

export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';

export const FETCH_ITEM_DETAILS_REQUEST = 'FETCH_ITEM_DETAILS_REQUEST';
export const FETCH_ITEM_DETAILS_SUCCESS = 'FETCH_ITEM_DETAILS_SUCCESS';
export const FETCH_ITEM_DETAILS_FAILURE = 'FETCH_ITEM_DETAILS_FAILURE';



//action creaters

export const fetchItemsRequest = () => {
    return {
        type: FETCH_ITEMS_REQUEST,
    }
}

export const fetchItemsSuccess = (items) => {
    return {
        type: FETCH_ITEMS_SUCCESS,
        payload:items,
    }
}

export const fetchItemsFailure = (error) => {
    return {
        type: FETCH_ITEMS_FAILURE,
        payload:error,
    }
}

// fetch product details by product id

export const fetchItemDetailsRequest = () => {
    return {
        type: FETCH_ITEM_DETAILS_REQUEST,
    }
}

export const fetchItemDetailsSuccess = (item) => {
    return {
        type: FETCH_ITEM_DETAILS_SUCCESS,
        payload:item,
    }
}

export const fetchItemDetailsFailure = (error) => {
    return {
        type: FETCH_ITEM_DETAILS_FAILURE,
        payload:error,
    }
}

//asyncronous actions to fetch items


export const fetchItems = () => {
    return async (dispatch) => {
        dispatch(fetchItemsRequest());
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product-list/`);
            dispatch(fetchItemsSuccess(response.data))
        } catch (error) {
            dispatch(fetchItemsFailure(error.message))
        }
    }
}

//get product using product id
 
  export const fetchProductDetailsByProductId =(productId)=>{
    console.log(productId);
    return async (dispatch) =>{
        dispatch(fetchItemDetailsRequest(productId));
        console.log(productId);
        try {
            console.log(productId);
            console.log(`${process.env.REACT_APP_BACKEND_URL}`);
            
            const response=await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product-detail/${productId}/`);
            dispatch(fetchItemDetailsSuccess(response.data))
            console.log("redux responce ",response.data);
            
        } catch (error) {
            dispatch(fetchItemDetailsFailure(error.message))
        }
    }
  }



