import React, { useEffect, useState } from 'react';
import './styles/bomview.css';
import CarouselImage from '../component/CarouselImage';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductDetailsByProductId } from '../redux/actions/itemActions';
import Loder from '../component/Loder';
import raw_metirials from '../assest/row.png';
import consumer from '../assest/consumer.png';
import dis from '../assest/dis.png';
import manufacture from '../assest/manufacture.png';
import retail from '../assest/retail.png';
import sup from '../assest/sup.png';
import { fetchItemsMaterials } from '../redux/actions/ItemMetirialActions';
import TableView from '../component/TableView';
import { MdArrowDropDownCircle } from "react-icons/md";
import Chart from '../component/Chart';


const ProductDetailsTable = ({ details }) => {
  // Define the keys of the fields you want to display

  const allowedFields = [
    'product_version',
    'brand',
    'manufacturer',
    'certification',
    'dimensions',
    'minimum_order_quantity',
    'seller',
    'supplier_ability',
    'package_details'
  ];

  return (
    <>

      {/* <div className='mx-3 mb-3'>{details.name && <h4>{details.name}</h4>}</div> */} 
      <table className='item-data-table2'>
        <tbody>
          {allowedFields.map((key) => (
            <tr key={key}>
              <td className='px-4'><strong>{key.replace(/_/g, ' ').toUpperCase()}</strong></td>
              <td>{details[key] || 'No information available'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};


const Bomview = () => {

  const [imageClick, setImageClick] = useState(false);
  const [clickedImage, setClickedImage] = useState(null);
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const { loading: loadingDetails, itemDetails = {}, error: errorDetails } = useSelector(state => state.itemsData);
  const { loading: loadingMaterials, itemsMetirials = {}, error: errorMaterials } = useSelector(state => state.itemsMetirials);


  const [hoveredRowMet, sethoveredRowMet] = useState(false);
  const [hoveredSuplier, sethoveredSuplier] = useState(false);
  const [hoveredManufacture, sethoveredManufacture] = useState(false);
  const [hoveredDistributor, sethoveredDistributor] = useState(false);
  const [hoveredConsumer, sethoveredConsumer] = useState(false);
  const [hoveredRetailer, sethoveredRetailer] = useState(false);
  // const itemsMaterials = [
  //   { Material: 'Steel', Description: 'Heavy-duty steel beam', Quantity: 50, Color: 'Silver', Unit: 'kg', Price: '$150' },
  //   { Material: 'Wood', Description: 'Oak wood plank', Quantity: 200, Color: 'Brown', Unit: 'm²', Price: '$80' },
  //   { Material: 'Cement', Description: 'Portland cement', Quantity: 100, Color: 'Gray', Unit: 'kg', Price: '$45' },
  //   // Add other items similarly...
  // ];

  const columns = ["Material", "Description", "Quantity", "Color", "Unit", "Price"];
  const data_names = ["materials", "description", "quantity", "colors", "unit", "price"];


  useEffect(() => {
    if (product_id) {
      dispatch(fetchProductDetailsByProductId(product_id));
      dispatch(fetchItemsMaterials(product_id));
    }
  }, [dispatch, product_id]);

  const handleImageClick = (image) => {
    setImageClick(true);
    setClickedImage(image);
  };

  console.log('itemsMetirials', itemsMetirials);
  console.log('itemid', itemDetails.id);


  const images = [
    itemDetails.image1_url,
    itemDetails.image2_url,
    itemDetails.image3_url,
    itemDetails.image4_url,
  ].filter(Boolean); // Filter out undefined URLs

  const generateYouTubeEmbedLink = (url) => {
    const videoIdMatch = url.match(/(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))([^&\n]{11})/);
    return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : null;
  };


  if (loadingDetails) return <div className='d-flex justify-content-center align-items-center' style={{ marginTop: '20%' }}><Loder /></div>;
  if (errorDetails) return <div className='d-flex justify-content-center align-items-center mt-5 text-danger'>Error: {errorDetails}</div>;

  return (
    <div className='container mt-4'>

      <h2>{itemDetails.name || 'Product Name Unavailable'}</h2>
      {/* <h2>{itemDetails.product_version || 'Product Name Unavailable'} v</h2> */}
      <div className='bom-container'>
        <div className='image-container-bom w-100'>
          <div className='shadow imgs-area'>
            <div className='image-slider shadow'>
              {imageClick ? (
                <img src={clickedImage} className='image-clicked zoom-image' alt="Product Image" />
              ) : (
                <CarouselImage images={images} />
              )}
            </div>
            <div className='d-flex align-items-center justify-content-left p-2 my-2'>
              {images.map((img, index) => (
                <div className='imgs-mini' key={index}>
                  <img src={img} className='item-imgs shadow' onClick={() => handleImageClick(img)} alt={`Thumbnail ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='data-container'>
          <div className='item-data-container m-5'>
            <div className='mx-3'>
              <div className='d-flex align-items-center '>
                {/* <h6>Product Review Details</h6> */}
                <h6 style={{ fontSize: "20px", fontWeight: "bold" }}>Product Review Details</h6>


                <MdArrowDropDownCircle className='mx-4' />
              </div>
              <p className='product-description ' style={{ textAlign: 'justify' }}>{itemDetails.description || 'No description available.'}</p>
            </div>
            <ProductDetailsTable details={itemDetails} />
          </div>
        </div>
      </div>

      <div className='product-info-card'>

        {/* {itemDetails.contents?.length > 0 ? (
          <>
            <h4>Contents:</h4>
            <ul className='content-list'>
              {itemDetails.contents.map((content, index) => (
                <li key={index}>{content}</li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <h4>Contents:</h4>
            <p>No contents available.</p>
          </>
        )} */}


        {itemDetails.features  ? (
          <>
            {/* <h4>Features</h4> */}
            <h4 className="bold-heading">Features</h4>

            {/* <ul className='package-list'>
              {itemDetails.features.map((pack, index) => (
                <li key={index}>
                  <strong>{pack.featureName}:</strong> {pack.details}
                </li>
              ))}
            </ul> */}
            <p className="custom-paragraph" style={{ textAlign: 'justify' }}>{itemDetails.features || 'No Features available.'}</p>
   

          </>
        ) : (
          <p className="custom-paragraph1" style={{ textAlign: 'justify' }}>{itemDetails.features || 'No Features available.'}</p>
          
        )}
        {/* 
<h4 className='my-2'>Packages </h4>

<h4 className='my-2'>Benefits of Using Cinnamon Oil in a Burner: </h4>
 */}


      </div>


      <TableView params={itemsMetirials} columns={columns} para_names={data_names} />


      {/* <div className='my-5'>
        <h4>Instructions to Use</h4>
        <p className='instructions'>{itemDetails.instructions || 'No instructions available.'}</p>
      </div> */}

      <div className='my-5'>
        <div className='text-center'>
          <h3>Supply Chain Management</h3>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-wrap my-5'>
          {/* <div
            className='text-center chain1'
            onMouseEnter={() => sethoveredRowMet(true)}
            onMouseLeave={() => sethoveredRowMet(false)}
          >
            <img src={raw_metirials} width={100} className='' alt="Product Image" />
            <h5>Raw Materials</h5>
          </div> */}

          <div
            className='text-center chain2'
            onMouseEnter={() => sethoveredSuplier(true)}
            onMouseLeave={() => sethoveredSuplier(false)}>
            <img src={sup} width={100} className='' alt="Product Image" />
            <h5>Supplier</h5>
          </div>

          <div
            className='text-center chain3'
            onMouseEnter={() => sethoveredManufacture(true)}
            onMouseLeave={() => sethoveredManufacture(false)}
          >
            <img src={manufacture} width={100} className='' alt="Product Image" />
            <h5>Manufacture</h5>
          </div>

          <div
            className='text-center chain4'
            onMouseEnter={() => sethoveredDistributor(true)}
            onMouseLeave={() => sethoveredDistributor(false)}>
            <img src={dis} width={100} className='' alt="Product Image" />
            <h5>Distributer</h5>
          </div>

          <div
            className='text-center chain5'
            onMouseEnter={() => sethoveredRetailer(true)}
            onMouseLeave={() => sethoveredRetailer(false)}>
            <img src={retail} width={100} className='' alt="Product Image" />
            <h5>Retailer</h5>
          </div>

          {/* <div
            className='text-center chain6'
            onMouseEnter={() => sethoveredConsumer(true)}
            onMouseLeave={() => sethoveredConsumer(false)}>
            <img src={consumer} width={100} className='' alt="Product Image" />
            <h5>Consumer</h5>
          </div> */}


        </div>
      </div>



      <div>
        {/* <h4 className='my-5'>Specific attributes</h4> */}
        <h4 className="my-5 bold-text">Specific attributes</h4>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            {/* <tr>
          <th style={{ border: '1px solid black', padding: '8px' }}>Category</th>
          <th style={{ border: '1px solid black', padding: '8px' }}>Details</th>
        </tr> */}
          </thead>
          <tbody>
            {/* <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>Packages</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Standard shipping packages available in various sizes</td>
            </tr> */}
            <tr className={`tableChain1 table-font ${hoveredSuplier ? 'tableRow' : ''}`} >
              <td style={{ border: '1px solid black', padding: '8px' }}>Supply</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Regular inventory supply for monthly distribution</td>
            </tr>
            {/* <tr className={`tableChain1 ${hoveredRowMet ? 'tableRow' : ''}`}>
              <td style={{ border: '1px solid black', padding: '8px' }}>Materials</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                <ul>
                  {
                    itemsMetirials && itemsMetirials.length > 0 ? (
                      itemsMetirials.map((mat, index) => (
                        <li><div key={index}>{mat.materials}</div></li>
                      ))
                    ) : (
                      <>No Metirials Available</>
                    )
                  }
                </ul>
              </td>
            </tr> */}
            <tr className={`tableChain1 table-font1  ${hoveredManufacture ? 'tableRow' : ''}`}>
              <td style={{ border: '1px solid black', padding: '8px' }}>Manufacture</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{itemDetails.manufacturer}</td>
            </tr>
            <tr className={`tableChain1 table-font2  ${hoveredDistributor ? 'tableRow' : ''}`}>
              <td style={{ border: '1px solid black', padding: '8px' }}>Distributer</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Recyclable and eco-friendly materials used</td>
            </tr>
            {/* <tr className={`tableChain1 ${hoveredConsumer ? 'tableRow' : ''}`} >
              <td style={{ border: '1px solid black', padding: '8px' }}>Safety Considerations</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Follow OSHA guidelines for workplace safety</td>
            </tr> */}
            <tr className={`tableChain1 table-font3 ${hoveredRetailer ? 'tableRow' : ''}`}>
              <td style={{ border: '1px solid black', padding: '8px' }}>Retailor</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>Encourages the reuse of packaging to reduce waste</td>
            </tr>
          </tbody>
        </table>
      </div>


      {/* <div>
        <h2>Additional Product Details</h2>
        <div className='item-data-container my-4'>
          <ProductDetailsTable details={itemDetails.additionalDetails || {}} />
        </div>
      </div> */}

      <div className='my-5'>
        <Chart />
      </div>

      <div className='mt-5'>
        <div className="video-container mt-5" >
          {itemDetails.youtube_link ? (
            <iframe
              className='ytv'
              src={generateYouTubeEmbedLink(itemDetails.youtube_link)}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <p>No YouTube video available.</p>
          )}
        </div>
      </div>
    </div >
  );
};

export default Bomview;
