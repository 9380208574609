import {
    ADD_NEW_USER_REQUEST,
    ADD_NEW_USER__SUCCESS,
    ADD_NEW_USER__FAILURE
} from '../actions/userAction';

const initialState = {
    loading: false,
    user: {},
    error: ''
};

const userReduser = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ADD_NEW_USER__SUCCESS:
            return {
                ...state,
                loading: false,
                user:action.payload,
                error:''
            };

        case ADD_NEW_USER__FAILURE:
            return {
                ...state,
                loading: false,
                user:{},
                error:action.payload,
            }

        default:
            return state;
    }
};

export default userReduser;

