import './NavigationBar.css';
import { useState, useEffect, useRef } from 'react';
import logo from '../assest/logog.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { IoMdShareAlt } from "react-icons/io";


function Navigationbar() {
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null); // Reference for the sidebar
    const sharePageRef = useRef(null); // Reference for the share page box
    const [isShowSharePage, setIsShowSharePage] = useState(false);
    const [isCopyUrl, setIsCopyUrl] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    // Close the sidebar and share box if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsOpen(false);
            }

            if (sharePageRef.current && !sharePageRef.current.contains(event.target)) {
                setIsShowSharePage(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleClickOutside);
        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sidebarRef, sharePageRef]);

    let url = window.location.href;

    const sharePage = () => {
        setIsShowSharePage(!isShowSharePage);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(url).then(() => {
            setIsCopyUrl(true); // Set to true after link is copied
            setTimeout(() => setIsCopyUrl(false), 2000); // Reset after 2 seconds
        });
    };

    return (
        <>
            <div className="navbar-container">
                <div className='d-flex justify-content-between align-item-center'>
                    <div>
                        <Link to="/" ><img src={logo} width={210} alt="logo" /></Link>
                    </div>
                    <div>
                        <button onClick={sharePage} className="toggle-button">
                            <IoMdShareAlt className='icon-nav-menu' />
                        </button>
                        <button onClick={toggleNavbar} className="toggle-button">
                            {isOpen ? <IoClose className='icon-nav-close' />
                                : <GiHamburgerMenu className='icon-nav-menu' />}
                        </button>
                    </div>
                </div>
                <div ref={sidebarRef} className={`nav-links ${isOpen ? 'open' : ''}`}>
                    <Link to="/login">Login</Link>
                    {/* Add other links as needed */}
                </div>
            </div>

            {isShowSharePage && (
                <div ref={sharePageRef}>
                    <div className="share-box">
                        <h3>Share this page</h3>
                        <div className="share-options">
                            <a
                                href={`https://wa.me/?text=Check%20this%20out:%20${encodeURIComponent(url)}`}
                                target="_blank"
                                className="share-option mx-2"
                                rel="noopener noreferrer"
                            >
                                <img src="https://img.icons8.com/color/48/000000/whatsapp.png" alt="WhatsApp" />
                                <span>WhatsApp</span>
                            </a>
                            <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
                                target="_blank"
                                className="share-option mx-2"
                                rel="noopener noreferrer"
                            >
                                <img src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="Facebook" />
                                <span>Facebook</span>
                            </a>
                            <a
                                href={`https://www.messenger.com/t?link=${encodeURIComponent(url)}`}
                                target="_blank"
                                className="share-option mx-2"
                                rel="noopener noreferrer"
                            >
                                <img src="https://img.icons8.com/color/48/000000/facebook-messenger.png" alt="Messenger" />
                                <span>Messenger</span>
                            </a>
                            <div className="share-option" onClick={copyLink}>
                                <img src="https://img.icons8.com/color/48/000000/copy.png" alt="Copy Link" />
                                <span>Copy Link</span>
                            </div>
                        </div>
                        <input
                            type="text"
                            className={isCopyUrl ? "copyedUrl" : "share-url"}
                            id="shareUrl"
                            value={url}
                            readOnly
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default Navigationbar;
