// App.js
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Navigationbar from './component/Navigationbar';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Passreset from './pages/Passreset';
import Bomview from './pages/Bomview';
import { AuthProvider } from './Auth/AuthProvider';
import PrivateRoute from './Auth/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navigationbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password-reset" element={<Passreset />} />
          <Route path="/bom/:product_id" element={<PrivateRoute component={Bomview} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
