import axios from 'axios';

export const ADD_NEW_USER_REQUEST = 'ADD_NEW_USER_REQUEST';
export const ADD_NEW_USER__SUCCESS = 'ADD_NEW_USER__SUCCESS';
export const ADD_NEW_USER__FAILURE = 'ADD_NEW_USER__FAILURE';


export const saveNewUserDetails = () => {
    return {
        type: ADD_NEW_USER_REQUEST,
    }
}

export const saveNewUserSuccess = (user) => {
    return {
        type: ADD_NEW_USER__SUCCESS,
        payload:user,
    }
}

export const saveNewUserFailure = (error) => {
    return {
        type: ADD_NEW_USER__FAILURE,
        payload:error,
    }
}


  //save new user

  export const saveNewUser=(user)=>{
    return async(dispatch)=>{
        dispatch(saveNewUserDetails(user))
        try {
            const response=await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/create`,user);
            dispatch(saveNewUserSuccess(response.data));
        } catch (error) {
            dispatch(saveNewUserFailure(error.response?.data?.message || error.message))
        }
    }
  }