import axios from 'axios';

export const FETCH_ITEMS_MATERIALS_REQUEST = 'FETCH_ITEMS_MATERIALS_REQUEST';
export const FETCH_ITEMS_MATERIALS_SUCCESS = 'FETCH_ITEMS_MATERIALS_SUCCESS';
export const FETCH_ITEMS_MATERIALS_FAILURE = 'FETCH_ITEMS_MATERIALS_FAILURE';

export const fetchItemsMaterialRequest = () => ({
    type: FETCH_ITEMS_MATERIALS_REQUEST,
});

export const fetchItemsMaterialSuccess = (data) => ({
    type: FETCH_ITEMS_MATERIALS_SUCCESS,
    payload: data,
});

export const fetchItemsMaterialFailure = (error) => ({
    type: FETCH_ITEMS_MATERIALS_FAILURE,
    payload: error,
});

export const fetchItemsMaterials = (productId) => {
    return async (dispatch) => {
        dispatch(fetchItemsMaterialRequest());
        try {
            console.log("productId",productId);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/material-by-product/${productId}/`);
            console.log("metirial",response);
            
            dispatch(fetchItemsMaterialSuccess(response.data));
        } catch (error) {
            dispatch(fetchItemsMaterialFailure(error.response?.data?.message || error.message));
        }
    };
};
