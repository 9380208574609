import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Typography } from '@mui/material';

// Define dataset with start and end days for each stage
const dataset = [
  { stage: 'Supply', start: 0, end: 5 },
  { stage: 'Manufacture', start: 6, end: 15 },
  { stage: 'Distributor', start: 16, end: 25 },
];

// Calculate the duration for each stage
const ganttData = dataset.map((item) => ({
  stage: item.stage,
  duration: item.end - item.start, // Calculate duration based on start and end
  start: item.start,
}));

// Value formatter to show "days"
const valueFormatter = (value) => `${value} days`;

function GanttChart() {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <Typography variant="h6" align="center" gutterBottom>
        Project Timeline (Gantt Chart)
      </Typography>
      <BarChart
        dataset={ganttData}
        xAxis={[
          {
            label: 'Days',
            min: 0,
            max: 30,
            tickSpacing: 5,
            valueFormatter,
          },
        ]}
        yAxis={[
          {
            scaleType: 'band',
            dataKey: 'stage',
            label: 'Stage',
          },
        ]}
        series={[
          {
            dataKey: 'duration',
            label: 'Duration (Days)',
            color: '#4caf50', // Customize bar color
          },
        ]}
        layout="horizontal"
        tooltip
      />
    </div>
  );
}

export default GanttChart;
